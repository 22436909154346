<template>
  <form
    id="tattoo-info"
    action
    data-vv-scope="tattoo-info-form"
    @submit.prevent="validateForm('tattoo-info-form')"
  >
    <v-row justify="center">
      <v-col cols="12" class="py-0">
        <ServiceSelector />
      </v-col>
      <v-col cols="12" md="7">
        <body-selector
          id="bodySelector"
          ref="body_selector"
          type="tattooer"
          :title="$t('select', { name: $t('appointments.body_part_tattoo') })"
        />
      </v-col>

      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="12" md="5" class="py-0">
            <label class="text-no-wrap">{{ $t("n_customers") }}</label>
            <v-select
              :items="range()(1, 5)"
              outlined
              dense
              hide-details
              v-model="n_customers"
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0">
            <label>{{ $t("appointments.description") }}</label>
            <v-textarea
              outlined
              v-model="customer.description"
              auto-grow
              rows="1"
              hide-details
              style="border-radius: 10px"
            ></v-textarea>
          </v-col>
          <template
            v-if="customer.tattooer && customer.tattooer.tattoo_type.length"
          >
            <v-col cols="12" class="py-0">
              <label>
                {{ $t("select", { name: $t("tattooers.tattoo_type") }) }}
              </label>

              <v-card class="my-0">
                <v-card-text class="pb-0">
                  <v-select
                    :items="customer.tattooer.tattoo_type"
                    return-object
                    outlined
                    dense
                    hide-details
                    item-text="name"
                    :placeholder="$t('select', { name: '' })"
                    v-model="customer.tattoo_type"
                    clearable
                  ></v-select>
                  <div v-if="customer.tattoo_type && customer.tattoo_type.src">
                    <v-simple-table id="simpleTable">
                      <tr>
                        <td :width="$vuetify.breakpoint.xsOnly ? '120' : '200'">
                          {{ $t("name") }}
                        </td>
                        <td>{{ customer.tattoo_type.name }}</td>
                      </tr>
                      <tr>
                        <td
                          style="position: relative"
                          :width="$vuetify.breakpoint.xsOnly ? '120' : '200'"
                        >
                          <span
                            style="
                              line-height: 0;
                              position: absolute;
                              top: 13px;
                            "
                            >{{ $t("inventorys.description") }}</span
                          >
                        </td>

                        <td>
                          {{
                            customer.tattoo_type.description &&
                            customer.tattoo_type.description.length > 50 &&
                            short
                              ? customer.tattoo_type.description.substring(
                                  0,
                                  50
                                ) + "..."
                              : customer.tattoo_type.description || "-"
                          }}<br />
                          <a
                            @click="short = !short"
                            v-if="
                              customer.tattoo_type.description &&
                              customer.tattoo_type.description.length > 50
                            "
                            ><u>{{
                              short ? $t("read_more") : $t("read_less")
                            }}</u></a
                          >
                        </td>
                      </tr>
                    </v-simple-table>
                  </div>
                </v-card-text>
                <v-img
                  v-if="customer.tattoo_type && customer.tattoo_type.src"
                  :src="customer.tattoo_type.src"
                  height="100"
                ></v-img>
                <v-img v-else height="100"></v-img>
              </v-card>
            </v-col>
          </template>

          <v-col cols="12" class="py-0">
            <label>{{ $t("appointments.examples_archived") }}</label>
            <file-upload
              style="width: 100%"
              :multiple="true"
              :drop="true"
              :drop-directory="false"
              accept="image/*"
              v-model="customer.files"
              ref="upload"
              class="pointer"
            >
              <div class="upload">
                <div v-if="customer.files.length" class="my-3">
                  <template v-for="(file, i) in customer.files">
                    <v-chip
                      v-if="i < 2"
                      :key="file.id"
                      outlined
                      color="primary"
                      style="max-width: 30%; vertical-align: middle"
                      class="no-wrap mx-1"
                      >{{ file.name }}
                    </v-chip>
                    <v-chip
                      v-if="i == 2"
                      :key="file.id"
                      outlined
                      color="primary"
                      class="mx-1"
                    >
                      + {{ customer.files.length - 2 }}
                      {{ $tc("file", customer.files.length - 2) }}
                    </v-chip>
                  </template>
                </div>
                <div v-else class="message">
                  <v-btn style="height: 30px" elevation="0"
                    ><v-icon small left>$files</v-icon>Adjuntar</v-btn
                  >
                  <div class="primary--text" style="">
                    {{ $t("tattoos.drop") }}
                  </div>
                </div>

                <div class="example-btn"></div>
              </div>
            </file-upload>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        v-if="!$route.params.tattooer_id || $route.params.pre"
        @click="
          $route.params.pre && $route.params.tattooer_id
            ? $router.push({
                name: 'customer_inscription_studio_pre',
                params: {
                  token: $route.params.token,
                  back: true,
                },
              })
            : $emit('back')
        "
        style="height: 30px; width: 115px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "TattooInfo",
  mounted() {
    setTimeout(() => {
      this.fetchBoxes();
    }, 1000);
  },
  data() {
    return {
      boxes: [],
      short: true,
      pay: null,
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      model: null,
      search: null,
    };
  },
  props: ["prepare"],
  mounted() {},
  methods: {
    ...mapGetters("app", ["range", "formatTime"]),
    async validateForm(scope) {
      //console.log("validateForm");
      this.errors.clear();
      let body_val = await this.$refs.body_selector.validateForm("body-form");
      this.$validator.validateAll(scope).then((result) => {
        //console.log("validateTattooerForm", result, body_val);
        if (result && body_val) {
          this.$emit("validated", this.studio);
        } else {
          this.$vuetify.goTo("#bodySelector");
        }
      });
    },
    removeFile(id) {
      this.customer.files = this.customer.files.filter((x) => x.id === id);
    },
    add(type) {
      this.$set(this.customer, "tattoo_type", type);
    },
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      if (this.$store.getters["auth/isLoggedIn"])
        this.getBoxes({
          pagination: this.options,
          filters: { ...this.filters, studio_id: this.customer.studio.id },
        }).then((boxes) => {
          this.boxes = boxes.data;
        });
    },
  },
  components: {
    BodySelector: () => import("@/components/ui/BodySelector"),
    FileUpload: () => import("vue-upload-component"),
    ServiceSelector: () => import("@/components/inscription/ServiceSelector"),
  },

  computed: {
    ...mapState("inscriptions", ["customerState", "tattooerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        console.log(this.customerState);
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },

    n_customers: {
      get() {
        return this.customer.extra_customers.length + 1;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_EXTRA_CUSTOMERS", value - 1);
      },
    },
    boxes_obligatoris() {
      return false;
      if (this.customer.tattooer && this.customer.tattooer.id === -1)
        return false;
      if (this.$store.getters["auth/isLoggedIn"]) {
        let s = this.customer.studio.user.setting.find(
          (x) => x.key === "box_active"
        );
        if (s && s.value === "1") {
          let s2 = this.customer.studio.user.setting.find(
            (x) => x.key === "boxes_obligatoris"
          );
          if (s2) {
            return s2.value === "1";
          }
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
label
  display: block
  height: 35px !important
.selected
  border: 1px solid var(--v-primary-base)
#simpleTable
  td
    padding: 3px 8px !important
  td:first-child
    color: var(--v-gris1-base)
.upload
  border-radius: 10px
  border: solid var(--v-primary-base) 1px
  text-align: center
  .message
    padding-top: 5px
</style>

<style lang="sass">
#tattoo-info
  .v-textarea fieldset
    //height: 62px
</style>